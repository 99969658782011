/* https://google-webfonts-helper.herokuapp.com/ */

/* ibm-plex-mono-100 - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 100;
  src: url("./ibm-plex-mono-v6-latin-100.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Thin"), local("IBMPlexMono-Thin"),
    url("./ibm-plex-mono-v6-latin-100.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-100.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-100.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-100italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 100;
  src: url("./ibm-plex-mono-v6-latin-100italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Thin Italic"), local("IBMPlexMono-ThinItalic"),
    url("./ibm-plex-mono-v6-latin-100italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-100italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-100italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-100italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-100italic.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-200 - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 200;
  src: url("./ibm-plex-mono-v6-latin-200.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono ExtraLight"), local("IBMPlexMono-ExtraLight"),
    url("./ibm-plex-mono-v6-latin-200.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-200.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-200.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-200italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 200;
  src: url("./ibm-plex-mono-v6-latin-200italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono ExtraLight Italic"),
    local("IBMPlexMono-ExtraLightItalic"),
    url("./ibm-plex-mono-v6-latin-200italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-200italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-200italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-200italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-200italic.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-300 - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 300;
  src: url("./ibm-plex-mono-v6-latin-300.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"),
    url("./ibm-plex-mono-v6-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-300.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-300italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 300;
  src: url("./ibm-plex-mono-v6-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"),
    url("./ibm-plex-mono-v6-latin-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-300italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-300italic.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-regular - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  src: url("./ibm-plex-mono-v6-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono"), local("IBMPlexMono"),
    url("./ibm-plex-mono-v6-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-regular.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 400;
  src: url("./ibm-plex-mono-v6-latin-italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"),
    url("./ibm-plex-mono-v6-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-italic.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-500 - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  src: url("./ibm-plex-mono-v6-latin-500.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Medium"), local("IBMPlexMono-Medium"),
    url("./ibm-plex-mono-v6-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-500.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-500italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 500;
  src: url("./ibm-plex-mono-v6-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Medium Italic"), local("IBMPlexMono-MediumItalic"),
    url("./ibm-plex-mono-v6-latin-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-500italic.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-600 - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 600;
  src: url("./ibm-plex-mono-v6-latin-600.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"),
    url("./ibm-plex-mono-v6-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-600.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-600italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 600;
  src: url("./ibm-plex-mono-v6-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono SemiBold Italic"),
    local("IBMPlexMono-SemiBoldItalic"),
    url("./ibm-plex-mono-v6-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-600italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-600italic.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-700 - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  src: url("./ibm-plex-mono-v6-latin-700.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"),
    url("./ibm-plex-mono-v6-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-700.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
/* ibm-plex-mono-700italic - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: italic;
  font-weight: 700;
  src: url("./ibm-plex-mono-v6-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Mono Bold Italic"), local("IBMPlexMono-BoldItalic"),
    url("./ibm-plex-mono-v6-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-mono-v6-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v6-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v6-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-mono-v6-latin-700italic.svg#IBMPlexMono") format("svg"); /* Legacy iOS */
}
