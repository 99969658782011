/* https://google-webfonts-helper.herokuapp.com/ */

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 100;
  src: url("./ibm-plex-sans-v8-latin-100.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"),
    url("./ibm-plex-sans-v8-latin-100.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-100.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-100.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-100italic - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 100;
  src: url("./ibm-plex-sans-v8-latin-100italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"),
    url("./ibm-plex-sans-v8-latin-100italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-100italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-100italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-100italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-100italic.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-200italic - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 200;
  src: url("./ibm-plex-sans-v8-latin-200italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans ExtraLight Italic"),
    local("IBMPlexSans-ExtraLightItalic"),
    url("./ibm-plex-sans-v8-latin-200italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-200italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-200italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-200italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-200italic.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-200 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 200;
  src: url("./ibm-plex-sans-v8-latin-200.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"),
    url("./ibm-plex-sans-v8-latin-200.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-200.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-200.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-200.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-300 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  src: url("./ibm-plex-sans-v8-latin-300.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"),
    url("./ibm-plex-sans-v8-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-300.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./ibm-plex-sans-v8-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans"), local("IBMPlexSans"),
    url("./ibm-plex-sans-v8-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-regular.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-300italic - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src: url("./ibm-plex-sans-v8-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"),
    url("./ibm-plex-sans-v8-latin-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-300italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-300italic.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-500 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./ibm-plex-sans-v8-latin-500.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"),
    url("./ibm-plex-sans-v8-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-500.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-italic - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src: url("./ibm-plex-sans-v8-latin-italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"),
    url("./ibm-plex-sans-v8-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-italic.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  src: url("./ibm-plex-sans-v8-latin-600.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"),
    url("./ibm-plex-sans-v8-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-600.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-500italic - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 500;
  src: url("./ibm-plex-sans-v8-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"),
    url("./ibm-plex-sans-v8-latin-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-500italic.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./ibm-plex-sans-v8-latin-700.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"),
    url("./ibm-plex-sans-v8-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-700.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-600italic - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  src: url("./ibm-plex-sans-v8-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans SemiBold Italic"),
    local("IBMPlexSans-SemiBoldItalic"),
    url("./ibm-plex-sans-v8-latin-600italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-600italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-600italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-600italic.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-700italic - latin */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  src: url("./ibm-plex-sans-v8-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"),
    url("./ibm-plex-sans-v8-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./ibm-plex-sans-v8-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-sans-v8-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-sans-v8-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./ibm-plex-sans-v8-latin-700italic.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
